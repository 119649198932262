<template>
  <div>
    <div class="all" v-if="btnFlag">
      <div class="arrowTip"  @click="toTop">
        <i  class="iconfont icon-arrowTop"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wechat',
  data () {
    return {
      btnFlag: false
    }
  },

  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    toTop () {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 10)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于200则隐藏
    scrollToTop () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      this.scrollTop = scrollTop
      if (this.scrollTop > 80) {
        this.btnFlag = true
      } else {
        this.btnFlag = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "iconfont"; /* Project id 2980387 */
  src: url('//at.alicdn.com/t/font_2980387_ea3yqv6bqyu.woff2?t=1638350960250') format('woff2'),
       url('//at.alicdn.com/t/font_2980387_ea3yqv6bqyu.woff?t=1638350960250') format('woff'),
       url('//at.alicdn.com/t/font_2980387_ea3yqv6bqyu.ttf?t=1638350960250') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrowTop:before {
  content: "\e65d";
}

.all {
  position: fixed;
  right: 5%;
  bottom: 24%;

  .arrowTip {
    width: 45px;
    height: 45px;
    border-radius: 6px;
    background: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 20px;
      display: block;
      color: #000000;
    }
    &:hover{
      background: #404698;
      color: #ffffff!important;
    }
  }
}
</style>
