<template>
  <div class="boxdataview">
    <!-- 标题头 -->
    <div class="toubuquyu flexy padding30" :style="{ background: bgtitle ? '#000000' : '' }">
      <div class="flexsb flexw flexy bioatounei flex1">
        <div>
          <img class="logoimg" src="../assets/icon_logo.png" alt="" srcset="" />
        </div>
        <div class="flexy ">
          <div class="titlename displaynone" v-for="(item, index) in $t('status')" :key="index" @click="ontitleback(index+1)" :style="{ color: activeindex == index + 1 ? '#FFFFFF' : '#CCCCCC' }">
            {{ $t("status")[index] }}
          </div>
          <div class="quehuanqu flexjend displayblocknone">
            <el-col :span="24">
              <span class="el-dropdown-link icon-list" @click="drawer = true">
                <i :class="[drawer?'el-icon-s-grid':'el-icon-menu']" style="font-size:20px"></i>
              </span>
            </el-col>
            <!-- <div class="quehuan flexsa flexy" @click="Butchange = !Butchange">
              {{ $t("chinese") }}
              <img src="../assets/icon_up.png" :style="{ width: '18px', height: '12px', transform: !Butchange ? 'rotate(180deg)' : 'rotate(0deg)', }" alt="" srcset="" />
            </div>
            <div class="zhongying" v-if="Butchange">
              <div @click="language('zh')">中文</div>
              <div @click="language('en')">English</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="bgtitle box_body" ref="titbox1">
      <div class="boxbodytitle paddingbottom padding30">
        <div class="jiesao fontsize20 margintop10 wow slideInLeft">
          {{ $t("TeaCeremony") }}
        </div>
        <div class="xaingxi fontsize16 wow slideInLeft">
          {{ $t("TeaCommentary") }}
        </div>
        <div class="xiazaiqianbao  marginauto wow slideInLeft anjiankuai" @click="imgewm = true">
          {{ $t("WalletDownload") }}
        </div>
        <!-- <a class="displayblocknone" href="https://www.teachain.com/wallet.jhtml" target="_blank">
          <div class="xiazaiqianbao marginauto wow slideInLeft anjiankuai">
            {{ $t("WalletDownload") }}
          </div>
        </a> -->
      </div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="jianjiequ box_body" ref="titbox2" id="id5">
      <div class="jianjiexiangmu flex flexsb flexw">
        <div class=" xaingmuji fontsize20 marginauto marginlrauto margintop marginbottom wow slideInRight ">
          {{ $t("ProjecTintroduction") }}
        </div>
        <div class="marginauto marginlrauto">
          <div class="jianshu fontsize16 wow slideInRight">
            {{ $t("Introductiondetails") }}
          </div>
          <a :href="locale == 'en'?'https://static.teachain.org/TeachainWhitePaper.pdf':'https://static.teachain.org/TeachainWhitePaper.pdf'" target="_blank">
            <div class="chakanshup anjiankuai margintop10    wow slideInLeft">
              {{ $t("seeWhitePaper") }}
            </div>
          </a>
        </div>
      </div>
      <!-- 视频 -->
      <div class="videoshipin flexcont margintop marginauto padding">
        <img style="width: 100%; height: auto" src="../assets/img_1.png" alt="" />
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="tujinigqu box_body " ref="titbox3">
      <div class="tujingkuai ">
        <div class="lujingtu margintop10 paddingtop20 fontsize20 wow slideInLeft">
          {{ $t("Road") }}
        </div>
        <!-- 手机版 -->
        <div class="luxianqukuai flex1 displayblocknone">
          <div class="jianbiantioa"></div>
          <div class="flex1" style="padding-top:50px">
            <div class="flex1 zhanshi margintop1 wow rotateInDownRight">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left:-25px "></div>
                <div class="nianfenqu">2019</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[0]">
              </div>
            </div>

            <div class="zhanshi margintop1 wow rotateInDownRight">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left:-25px "></div>
                <div class="nianfenqu">2020</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[1]">
              </div>
            </div>

            <div class="zhanshi margintop1 wow rotateInDownRight">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left:-25px "></div>
                <div class="nianfenqu">2021</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[2]" :style="{color:Year == 2021?'#FFF':''}">
              </div>
            </div>

            <div class="zhanshi margintop1 wow rotateInDownRight">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left:-25px "></div>
                <div class="nianfenqu">2022</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[3]" :style="{color:Year == 2022?'#FFF':''}">
              </div>
            </div>

          </div>
        </div>
        <!-- pc版 -->
        <div class="mokuaui flexx displaynone ">
          <div class="flex1">
            <div class="zhanshi maringleft wow rotateInDownLeft">
              <div class="flexjend">
                <div class="nianfenqu">2019</div>
                <div class="xiaodian" style="position: relative; right: -28px;"></div>
              </div>
              <div style="text-align: right;" class="fontsize16" v-html="$t('Roadmap')[0]">
              </div>
            </div>
            <div class="zhanshi maringleft wow rotateInDownLeft">
              <div class="flexjend">
                <div class="nianfenqu">2021</div>
                <div class="xiaodian" style="position: relative; right: -28px"></div>
              </div>
              <div class="fontsize16" style="text-align: right;" v-html="$t('Roadmap')[1]" :style="{color:Year == 2021?'#FFF':''}">
              </div>
            </div>
          </div>
          <div class="jianbiantioa"></div>
          <div class="flex1">
            <div class="zhanshi margintop1 wow rotateInDownRight">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left: -28px"></div>
                <div class="nianfenqu">2020</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[2]">
              </div>
            </div>

            <div class="zhanshi margintop1 wow rotateInDownRight margintop" style="margin-top:0">
              <div class="flex">
                <div class="xiaodian" style="position: relative; left: -28px"></div>
                <div class="nianfenqu">2022</div>
              </div>
              <div class="fontsize16" v-html="$t('Roadmap')[3]" :style="{color:Year == 2022?'#FFF':''}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="gaunyuwom box_body" ref="titbox4" id="id2">
      <div class="guanyuqu fontsize20 paddingtop20 wow slideInLeft">
        {{ $t("status")[1] }}
      </div>
      <div class="beijingqujin flexy ">
        <div class=" flexsa displayblocknone fontsize16 zhaoxiaqu ">
          <div v-for="(item, index) in $t('supporttitle')" class="titlexuanxiang flexcont" @click="support = index" :key="item" :style="{ background: support == index ? 'linear-gradient(90deg, #12f7b2 0%, #da28fc 100%)' : '#000000', fontWeight: support == index ? 'bold' : '', }">
            <div class="fenzhiqukuai flex1">
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div class="flexsb classfangan flexw margintop10">
        <div class="flex1 flex flexcont displaynone" style="margin: 0 auto">
          <div class="zhichu flexcont marginauto wow slideInRight">
            <div class="zhichi" @click="support = 0">
              <div class="biaotiqu">ABOUT TEACHAIN</div>
              <div class="jishuzhi">{{ $t("supporttitle")[0] }}</div>
              <div class="jianjiechi">
                {{ $t("commentary")[0] }}
              </div>
            </div>
          </div>
          <div class="zhichu flexcont marginauto wow slideInRight" style="margin-top: 90px">
            <div class="zhichi" @click="support = 1">
              <div class="biaotiqu">ABOUT TEACHAIN</div>
              <div class="jishuzhi">{{ $t("supporttitle")[1] }}</div>
              <div class="jianjiechi">
                {{ $t("commentary")[1] }}
              </div>
            </div>
          </div>
          <div class="zhichu flexcont marginauto wow slideInRight" style="margin-top: 180px">
            <div class="zhichi" @click="support = 2">
              <div class="biaotiqu">ABOUT TEACHAIN</div>
              <div class="jishuzhi">{{ $t("supporttitle")[2] }}</div>
              <div class="jianjiechi">
                {{ $t("commentary")[2] }}
              </div>
            </div>
          </div>
        </div>
        <div class="shujulian margintop10 padding" style="margin: 0 auto; margin-top: 200px">
          <div class="biaotic wow slideInRight fontsize20 displaynone">
            {{ $t("supporttitle")[support] }}
          </div>
          <div class="huatiao marginauto displaynone"></div>
          <div class="neirojiang fontsize16 wow slideInRight" v-html="$t('supportdata')[support]">
          </div>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="guanjianjishu box_body" ref="titbox5" id="id3">
      <div class="guanjaintitle paddingtop20 fontsize20 wow slideInLeft">
        {{ $t("status")[2] }}
      </div>
      <div class="jishuwaicheng padding">
        <div class="flexsa flexw">
          <div class="fangyuanxito anjiankuai flexcont margintop wow slideInLeft" @click="systemid = 0" :style="{ backgroundImage: 'url(' + systembgimg[systemid == 0 ? 0 : 1] + ')', }">
            {{ $t("systemname")[0] }}
          </div>
          <div class="fangyuanxito2 anjiankuai margintop flexcont wow slideInLeft" @click="systemid = 1" :style="{ backgroundImage: 'url(' + systembgimg[systemid == 0 ? 1 : 0] + ')', }">
            {{ $t("systemname")[1] }}
          </div>
        </div>
        <div>
          <div class="flex displaynone">
            <div class="wangwei flexy marginauto">
              <div class="bianju"></div>
              <div style="padding: 0 20px" class="flex1 flexcont wow slideInRight">
                {{ $t("systemname")[systemid] }}
              </div>
            </div>
          </div>
          <div class="fangyuanqu paddingtop20 fontsize16 wow slideInRight">
            {{ $t("systemtitle")[systemid] }}
          </div>
          <!-- 手机版 -->
          <el-carousel :interval="4000" indicator-position="outside" @change="swiperchang1" height="514px" class="displayblocknone">
            <el-carousel-item background-color="none" v-for="(item,index) in $t('systemdata')[systemid]" :key="index">
              <div class="mokauiqu wow slideInRight" :style="{background:swiperindex1 == index?'linear-gradient(180deg, #12F7B2 0%, #DA28FC 100%)':'rgba(26,26,26,0.9)'}">
                <div class="mokauiqu2QU" :style="{background:swiperindex1 == index?'rgba(26,26,26,1)':''}">
                <div class="biaotiqu fontsize20">
                  {{ item.id }}. {{ item.title }}
                </div>
                <div class="namebox fontsize16 paddingtop">
                  {{ item.name }}
                </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- pc -->
          <el-carousel :interval="4000" type="card" @change="swiperchang" class="displaynone" height="514px">
            <el-carousel-item background-color="none" v-for="(item,index) in $t('systemdata')[systemid]" :key="index">
              <div class="mokauiqu wow slideInRight" :style="{background:swiperindex == index?'linear-gradient(180deg, #12F7B2 0%, #DA28FC 100%)':'rgba(26,26,26,0.9)',boxShadow: '0px 0px 50px rgba(18, 247, 178, 0.5)'}">
                <div class="mokauiqu2QU" :style="{background:swiperindex == index?'rgba(26,26,26,1)':''}">
                  <div class="biaotiqu fontsize20">
                    {{ item.id }}. {{ item.title }}
                  </div>
                  <div class="namebox fontsize16 paddingtop">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="nftjaingjia box_body" ref="titbox6" id="id4">
      <div class="flexsb box-wai flexw paddingtop">
        <div class="tupiankuai margintop10 marginauto marginlrauto">
          <img style="width: 100%; height: auto" src="../assets/img_2.png" alt="" />
        </div>
        <div class="jiafangjn marginauto marginlrauto padding">
          <div class="biaotinft fontsize20 marginauto wow slideInLeft">
            {{ $t("status")[3] }}
          </div>
          <div class="zhujiekuai fontsize20 wow slideInLeft">
            Introduction to NFTEA
          </div>
          <div class="xaingxijie fontsize16 wow slideInRight">
            {{ $t("NFTEAcommentary") }}
          </div>
          <a href="https://test.nft.teachains.cn/?utm_source=tokenpocket" target="_blank">
            <div class="liaojiegengdui flexcont  wow slideInLeft anjiankuai marginleft">
              {{ $t("Learnmore") }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <div class="dibuqu flexcont box_body" ref="titbox7">
      <div>
        <div class="lianxiwomen flexcont fontsize20 paddingtop20 wow slideInLeft">
          {{ $t("contactus") }}
        </div>
        <div class="flexsa wow slideInLeft">
          <a href="https://t.me/Teachainglobal" target="_blank">
            <img class="feishu" src="../assets/icon_Telegram.png" alt="" srcset="" />
          </a>
          <a href="https://www.facebook.com/teachaingroup" target="_blank">
            <img class="feishu" src="../assets/icon_facebook.png" alt="" srcset="" />
          </a>
          <a href="https://www.twitter.com/TeaChainGroup" target="_blank">
            <img class="feishu" src="../assets/icon_Twitter.png" alt="" srcset="" />
          </a>
          <a href="https://discord.gg/WSkrAa3b" target="_blank">
            <img class="feishu" src="../assets/icon_discord.png" alt="" srcset="" />
          </a>
          <a href="https://github.com/" target="_blank">
            <img class="feishu" src="../assets/icon_github.png" alt="" srcset="" />
          </a>
        </div>
        <div class="flexcont shouxiang" style="padding-bottom: 30px;">
          <a href="mailto:service@teachain.com" target="_blank">
            <img style="margin-right: 10px" src="../assets/icon_yx.png" alt="" /></a>
          <div style="text-decoration:underline" v-clipboard:copy="'service@teachain.com'" v-clipboard:success="onCopy">service@teachain.com</div>
        </div>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////? -->
    <zhiding class="displaynone" />

    <el-drawer :visible.sync="drawer" :withHeader="false" direction="ttb" :show-close="false" append-to-body>
      <div class="caidanlan" :style="{color:activeindex == index+1?'#da28fc':''}" v-for="(item, index) in $t('status')" :key="index" @click="ontitleback(index+1)">
        {{ $t("status")[index] }}
      </div>
    </el-drawer>
    <el-dialog title="Tips" :before-close="handleClose" :visible.sync="dialogTableVisible" :width="windowWidth >750?'50%':'90%'">
      <div style="width:100%;height:100%" class="gonggaoqu">
        <div class="flexcont">
          <h1>
            {{$t('Notice')[0]}}
          </h1>
        </div>
        <div style="line-height: 40px;">
          {{$t('Notice')[1]}}
        </div>
        <div style="text-indent:50px;line-height: 30px;  ">
          {{$t('Notice')[2]}}

        </div>
        <div style="text-indent:50px;line-height: 30px;  ">
          {{$t('Notice')[3]}}
        </div>
        <div style="text-indent:50px;line-height: 30px;  ">
          {{$t('Notice')[4]}}
        </div>
        <div class="flexjend" style="margin-top:30px">
          {{$t('Notice')[5]}}
        </div>
        <div class="flexjend" style="margin-top:20px">
          {{$t('Notice')[6]}}
        </div>
      </div>
    </el-dialog>
    <el-dialog :before-close="()=>imgewm = false" :visible.sync="imgewm" width="240px">
      <img style="width:200px" src="../assets/img_ewm.png" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import zhiding from "@/common/zhiding/zhiding.vue";
// import pdf from "vue-pdf";
// import { Loading  } from 'element-ui'
export default {
  name: "show",
  components: { zhiding },
  data() {
    return {
      systembgimg: [
        require("../assets/img_bt2.png"),
        require("../assets/img_bt3.png"),
      ],
      support: 0, //技术支持
      systemid: 0, //系统
      Butchange: false,
      imgewm: false, //下载地址
      activeindex: -1, //高亮下标
      bgtitle: false, //头部背景
      falgWidth: false, //窗口大小
      Year: new Date().getFullYear(), //获取当前年份
      locale: "zh",
      dialogTableVisible: true, //广告弹窗
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      drawer: false, //侧边导航栏
      swiperindex: 0,
      swiperindex1: 0,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.titbox1.style.backgroundSize = `${this.windowWidth}px auto`;
      this.$refs.titbox2.style.backgroundSize = `${this.windowWidth}px 100%`;
      this.$refs.titbox3.style.backgroundSize = `${this.windowWidth}px 100%`;
      this.$refs.titbox4.style.backgroundSize = `${this.windowWidth}px 100%`;
      this.$refs.titbox5.style.backgroundSize = `${this.windowWidth}px auto`;
      this.$refs.titbox6.style.backgroundSize = `${this.windowWidth}px 100%`;
      this.$refs.titbox7.style.backgroundSize = `${this.windowWidth}px auto`;
    });

    let _this = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        _this.windowHeight = window.fullHeight; // 高
        _this.windowWidth = window.fullWidth; // 宽
      })();
    };

    // 公告广告弹窗
    if (this.$cookies.get("dialogTableVisible")) {
      this.dialogTableVisible = false;
    }

    if (
      document.body.offsetWidth > 1024 &&
      !navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
          offset: 100,
        });
        wow.init();
      });
    } else {
      this.falgWidth = true;
    }

    //获取系统语言
    // if (navigator.languages[0] != "zh-CN") {
    //   this.$i18n.locale = "en";
    //   this.locale = "en";
    // }
    // if (this.$cookies.get("i18n")) {
    //   //记录上一次的语言却换
    //   this.$i18n.locale = this.$cookies.get("i18n");
    //   this.locale = this.$cookies.get("i18n");
    // }
    window.addEventListener("scroll", () => {
      this.bgtitle = true;
      var abs =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;
      if (abs == 0) {
        this.bgtitle = false;
      }
    });
  },
  methods: {
    swiperchang1(index) {
      console.log(index);
      this.swiperindex1 = index;
    },
    swiperchang(index) {
      this.swiperindex = index;
    },

    // 关闭提示
    handleClose(done) {
      this.$cookies.set("dialogTableVisible", false);
      this.dialogTableVisible = false;
    },

    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
      this.pageCount = this.bookPages[this.$route.query.book];
    },

    // 国际语言切换
    language(name) {
      this.$cookies.set("i18n", name);
      this.$i18n.locale = name;
      this.Butchange = false;
      this.locale = name;
    },

    // 滚动到指定位置
    ontitleback(index) {
      this.activeindex = index;
      this.drawer = false;
      if (index == 6) {
        this.$notify({
          type: "warning",
          duration: 1000,
          showClose: false,
          position: "bottom-right",
          message: "Coming soon...",
        });
        return;
      } else if (index == 1) {
        this.bgtitle = false;
        document.documentElement.scrollTop = 0;
        window.pageYOffset = 0;
        document.body.scrollTop = 0;
        return;
      }
      // 获取监听元素距离视窗顶部距离
      var offsetTop = document.getElementById(`id${index}`).offsetTop;
      // 则表示页面已经滚动到可视区了。
      let abs = this.windowWidth;
      document.documentElement.scrollTop = offsetTop - (abs <= 750 ? 60 : 80);
      window.pageYOffset = offsetTop - (abs <= 750 ? 60 : 80);
      document.body.scrollTop = offsetTop - (abs <= 750 ? 60 : 80);
    },

    onCopy() {
      this.$notify({
        type: "success",
        duration: 1000,
        showClose: false,
        position: "bottom-right",
        message: "Copy succeeded",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./shou.scss";

.caidanlan {
  padding: 15px;
  height: 50px;
  color: #000000;
  font-size: 13px;
}

.gonggaoqu {
  color: #000000;
}

.el-carousel__item:nth-child(n) {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 20px;
}
.dibuqu {
  max-width: 100%;
  min-height: 1280px;
  background-image: url("../assets/bg7.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  box-sizing: border-box;
  .shouxiang {
    div {
      cursor: pointer;
    }
  }
  .lianxiwomen {
    position: relative;
    top: 25px;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .feishu {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 50px 5px;
  }
  .lianxiwomen {
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
  }
}
.nftjaingjia {
  min-height: 980px;
  max-width: 100%;
  background-image: url("../assets/bg6.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  .box-wai {
    max-width: 1520px;
    margin: 0 auto;
    padding-top: 155px;
    .jiafangjn {
      padding: 0 30px;
      width: 33.75rem;
      .liaojiegengdui {
        cursor: pointer;
        width: 234px;
        height: 60px;
        background-image: url("../assets/img_bt.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
      }
      .xaingxijie {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #cccccc;
        opacity: 0.98;
        margin: 30px 0 30px 0;
      }
      .zhujiekuai {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        opacity: 1;
      }
      .biaotinft {
        font-size: 62px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 106px;
      }
    }
    .tupiankuai {
      width: 810px;
    }
  }
}

.guanjianjishu {
  max-width: 100%;
  min-height: 1335px;
  background-image: url("../assets/bg5.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 3.125rem;
  .jishuwaicheng {
    max-width: 1520px;
    margin: 0 auto;
    padding: 30px;
    .fangyuanqu {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: #cccccc;
      opacity: 1;
      padding: 60px 0 30px;
    }
    .mokauiqu {
      width: 100%;
      height: 100%;
      padding: 2px;
      box-sizing: border-box;
      .mokauiqu2QU {
        border-radius: 20px;
        margin: auto;
        width: 100%;
        height: 100%;
      }
      .namebox {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #eeeeee;
        opacity: 1;
        padding: 30px;
        overflow-y: auto;
      }
      .biaotiqu {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        padding: 30px;
      }
    }
    .wangwei {
      margin-top: 146px;
      min-width: 202px;
      height: 48px;
      background: rgba(255, 255, 255, 0.1);
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 30px;
      color: #ffffff;
      .bianju {
        width: 6px;
        height: 28px;
        background: linear-gradient(180deg, #12f7b2 0%, #da28fc 100%);
        opacity: 1;
      }
    }
    .fangyuanxito2 {
      cursor: pointer;
      margin-top: 80px;
      width: 400px;
      height: 100px;
      font-size: 32px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      opacity: 1;
      text-align: center;
    }
    .fangyuanxito {
      cursor: pointer;
      margin-top: 80px;
      width: 400px;
      height: 100px;
      font-size: 32px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      opacity: 1;
      text-align: center;
    }
  }
  .guanjaintitle {
    text-align: center;
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 112px;
    color: #ffffff;
    opacity: 1;
  }
}
.gaunyuwom {
  max-width: 100%;
  min-height: 1644px;
  background-image: url("../assets/bg4.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  padding-top: 12.5rem;
  box-sizing: border-box;
  .titlexuanxiang {
    padding: 2px;
    border-radius: 117px;
    box-sizing: border-box;
    .fenzhiqukuai {
      padding: 10px;
      background: #141415;
      border-radius: 117px;
    }
  }
  .classfangan {
    max-width: 1520px;
    margin: 0 auto;
    .zhichu {
      width: 234px;
      height: 812px;
      background-image: url("../assets/img_about.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-right: 34px;
      .zhichi {
        position: relative;
        left: -50%;
        cursor: pointer;
        padding: 120px 30px 30px;
        width: 234px;
        min-height: 494px;
        background: RGBA(21, 21, 23, 0.9);
        opacity: 1;
        border-radius: 117px;
        box-sizing: border-box;
        .jianjiechi {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 24px;
          opacity: 1;
          color: rgba(204, 204, 204, 0.9);
        }
        .jishuzhi {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          opacity: 1;
          margin: 10px 0 20px;
        }
        .biaotiqu {
          width: 79px;
          height: 46px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 24px;
          color: rgba(204, 204, 204, 0.9);
        }
      }
    }
    .shujulian {
      width: 599px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #cccccc;
      padding: 0 30px;

      .neirojiang {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        color: rgba(204, 204, 204, 0.9);
        opacity: 1;
      }
      .huatiao {
        width: 273px;
        height: 4px;
        background: linear-gradient(270deg, #12f7b2 0%, #da28fc 100%);
        opacity: 1;
        margin: 30px 0 60px 0;
      }
      .biaotic {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .guanyuqu {
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
    text-align: center;
  }
}
.tujinigqu {
  max-width: 100%;
  min-height: 1401px;
  background-image: url("../assets/bg3.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;
  .tujingkuai {
    margin: 0 auto;
    max-width: 1520px;
    .mokuaui {
      position: relative;
      margin: 0 auto;
      color: #999999;
      padding-top: 154px;
      box-sizing: border-box;
      .nianfenqu {
        color: #fff;
      }
      .maringleft {
        margin-left: auto;
        margin-right: 22px;
      }
      .margintop1 {
        margin-top: 50px;
        margin-left: 22px;
      }
      .zhanshi {
        padding-bottom: 100px;
        .xiaodian {
          z-index: 10;
          width: 8px;
          height: 8px;
          background: #12f7b2;
          border-radius: 50%;
          border: 2px solid #fff;
          box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
        }
      }
      .jianbiantioa {
        position: absolute;
        top: 0px;
        margin: 40px 0 0;
        width: 2px;
        height: 668px;
        box-sizing: border-box;
        background: linear-gradient(
          117deg,
          rgba(218, 40, 252, 0) 0%,
          #da28fc 51%,
          rgba(218, 40, 252, 0) 100%
        );
        opacity: 1;
      }
    }
    .lujingtu {
      margin-top: 14.8125rem;
      text-align: center;
      font-size: 80px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
    }
  }
}

.jianjiequ {
  max-width: 100%;
  background-image: url("../assets/bg2.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 8.1875rem;
  padding-bottom: 4.9375rem;
  .videoshipin {
    padding: 30px;
    margin: 0 auto;
    margin-top: 12.1875rem;
    max-width: 1520px;
    opacity: 1;
    color: #fff;
  }
  .jianjiexiangmu {
    max-width: 1520px;
    margin: 0 auto;
    height: 100%;
    .chakanshup {
      cursor: pointer;
      text-align: center;
      margin-top: 3.6875rem;
      width: 15rem;
      height: 4.125rem;
      background-image: url("../assets/img_bt.png");
      background-size: cover;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 4.125rem;
      color: #ffffff;
      opacity: 1;
    }
    .jianshu {
      max-width: 45rem;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      opacity: 1;
    }
    .xaingmuji {
      margin-left: 50px;
      width: 25.25rem;
      height: 21rem;
      font-size: 80px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
    }
  }
}

.toubuquyu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  height: 80px;
  .bioatounei {
    max-width: 1520px;
    height: 100%;
    margin: 0 auto;
    .quehuanqu {
      position: relative;
      cursor: pointer;
      .zhongying {
        position: absolute;
        top: 50px;
        width: 152px;
        height: 112px;
        line-height: 50px;
        background: #ffffff;
        opacity: 1;
        border-radius: 22px;
        text-align: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .zhongying div:hover {
        color: #da28fc;
      }
    }
    .quehuan {
      position: relative;
      margin-left: 4.375rem;
      text-align: center;
      width: 152px;
      height: 43px;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 43px;
      color: #ffffff;
      opacity: 1;
    }
    .titlename {
      cursor: pointer;
      margin-left: 4.375rem;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.bgtitle {
  background-image: url("../assets/bg1.png");
  // background-size: 100% cover;
  background-repeat: no-repeat;
  .boxbodytitle {
    max-width: 1520px;
    padding-top: 2.875rem;
    margin: 0 auto;
    .xaingxi {
      max-width: 52.5rem;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      opacity: 1;
    }
    .jiesao {
      display: inline-block;
      text-align: center;
      margin-top: 350px;
      font-size: 80px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
    }
    .xiazaiqianbao {
      cursor: pointer;
      text-align: center;
      margin-top: 3.75rem;
      margin-bottom: 8.75rem;
      width: 15rem;
      height: 4.125rem;
      background-image: url("../assets/img_bt.png");
      background-size: cover;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 4.125rem;
      color: #ffffff;
      opacity: 1;
      position: relative;
      img {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 100px;
        left: 25%;
      }
    }
  }
}
</style>
